












































































































































import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import { Component, Vue, Watch } from 'vue-property-decorator'
import DropdownMultiSelect from '@/app/ui/views/Driver/components/DropdownMultiSelect/index.vue'
import {
  ICourierCancelListParameters,
  ICourierCancelListParams,
  IHeaderCell,
  IOptions,
  ITableCell,
} from '@/data/infrastructures/misc/interfaces/cancelValidation'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import controller from '@/app/ui/controllers/ManageCourierController'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import { ManageCourier } from '@/domain/entities/ManageCourier'
import {
  CourierSortFields,
  CourierSortType,
} from '@/app/infrastructures/misc/Constants/cancelValidation'
import AscendingIcon from '@/app/ui/assets/ascending_icon.vue'
import { Utils } from '@/app/infrastructures/misc'
import EmptyState from '@/app/ui/components/EmptyState/EmptyState.vue'
import routeController from '@/app/ui/controllers/RouteController'
import { LetterCode } from '@/domain/entities/Route'

@Component({
  components: {
    DropdownSelect,
    TextInput,
    DropdownMultiSelect,
    LoadingOverlay,
    DataTableV2,
    PaginationNav,
    AscendingIcon,
    EmptyState,
  },
})
export default class CourierCancelList extends Vue {
  controller = controller
  routeController = routeController

  enumSelectedSort = CourierSortType
  enumSortFields = CourierSortFields

  originOptions: IOptions[] = []

  selectedSort: CourierSortType | null = null

  courierTypeOptions: IOptions[] = [
    {
      label: 'Semua',
      value: '',
    },
    {
      label: 'KVP',
      value: 'KVP',
    },
    {
      label: 'POS',
      value: 'POS',
    },
  ]

  parameters: ICourierCancelListParameters = {
    page: 1,
    perPage: 50,
    districtCode: [],
    search: '',
    courierType: this.courierTypeOptions[0],
  }

  headers = [
    this.headerCellMapper('No.', '60px'),
    this.headerCellMapper('Nama Kurir', '220px'),
    this.headerCellMapper('Nomor HP', '170px'),
    this.headerCellMapper('Jenis Kurir', '114px'),
    this.headerCellMapper('3LC', '80px'),
    this.headerCellMapper('Aksi', '120px'),
  ]

  tableData: ITableCell[][] = []

  created(): void {
    document.title =
      'List Kurir Cancel | Content Management System | Lionparcel'
    this.getCourierList(true)
    this.routeController.getLetterCodes()
  }

  get params(): ICourierCancelListParams {
    return {
      sortColumn: this.selectedSort ? this.selectedSort.split('-')[0] : '',
      sortBy: this.selectedSort ? this.selectedSort.split('-')[1] : '',
      driverType: <string>this.parameters.courierType.value,
      page: this.parameters.page,
      perPage: this.parameters.perPage,
      q: this.parameters.search,
      origin: this.parameters.districtCode.join(','),
      courierStatus: 'active',
    }
  }

  private getCourierList(reset = false): void {
    if (reset) {
      this.parameters.page = 1
    }

    this.controller.getManageCourierList(
      <Record<string, string | number>>this.params
    )
  }

  public onSearch = Utils.debounce((keyword: string) => {
    if (keyword.length === 0 || keyword.length >= 3) {
      this.getCourierList(true)
    }
  }, 500)

  public onChangeCourierType(type: IOptions): void {
    if (!type) {
      this.parameters.courierType = this.courierTypeOptions[0]
    }
    this.getCourierList(true)
  }

  public onChangeDistrictCode(code: string[]): void {
    this.parameters.districtCode = code
    this.getCourierList(true)
  }

  public onTableSort(sortType: CourierSortFields): void {
    switch (sortType) {
      case CourierSortFields.COURIER_NAME:
        if (this.selectedSort === CourierSortType.COURIER_NAME_ASC) {
          this.selectedSort = CourierSortType.COURIER_NAME_DESC
        } else if (this.selectedSort === CourierSortType.COURIER_NAME_DESC) {
          this.selectedSort = null
        } else {
          this.selectedSort = CourierSortType.COURIER_NAME_ASC
        }
        break
      case CourierSortFields.COURIER_PHONE_NUMBER:
        if (this.selectedSort === CourierSortType.COURIER_PHONE_NUMBER_ASC) {
          this.selectedSort = CourierSortType.COURIER_PHONE_NUMBER_DESC
        } else if (
          this.selectedSort === CourierSortType.COURIER_PHONE_NUMBER_DESC
        ) {
          this.selectedSort = null
        } else {
          this.selectedSort = CourierSortType.COURIER_PHONE_NUMBER_ASC
        }
        break
    }
    this.getCourierList(true)
  }

  private headerCellMapper(
    title: string | number,
    colWidth: string,
    textAlign?: string
  ): IHeaderCell {
    return {
      title: title,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
        textAlign: textAlign,
      },
    }
  }

  private tableCellMapper(
    value: string | number | Date | string[],
    colWidth: string,
    justifyContent?: string
  ): ITableCell {
    return {
      value,
      customStyle: {
        maxWidth: colWidth,
        minWidth: colWidth,
        display: 'flex',
        justifyContent: justifyContent || '',
      },
    }
  }

  @Watch('controller.manageCourierData')
  setCourierListData(data: ManageCourier[]): void {
    this.tableData = data.map((item, index: number) => {
      return [
        this.tableCellMapper(
          index +
            1 +
            this.parameters.perPage * (this.parameters.page - 1) +
            '.',
          '60px',
          'center'
        ),
        this.tableCellMapper(
          `[${item.courierId}] ${item.fullName} </br> (${item.courierTypeValid}) ${item.phoneNumber}`,
          '220px'
        ),
        this.tableCellMapper(<string>item.phoneNumber, '170px'),
        this.tableCellMapper(<string>item.courierTypeValid, '114px'),
        this.tableCellMapper(<string>item.origin, '80px'),
        this.tableCellMapper(<number>item.courierId, '120px'),
      ]
    })
  }

  @Watch('routeController.letterCodes')
  onLetterCodesChanged(data: LetterCode[]): void {
    this.originOptions = data.map(route => {
      return {
        label: <string>route.lc,
        value: <string>route.lc,
      }
    })
  }
}
